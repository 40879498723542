import "./styles.css";

import CtaWpp from "../CTAWpp";
import CtaTel from "../CTATel";

const CtaMenu = () => {
  return (
    <article id="ctaMenu">
      <div className="container">
        <CtaWpp text={"(92) 99331-0599"} />
        <CtaTel />
      </div>
    </article>
  );
};

export default CtaMenu;
