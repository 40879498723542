import "./styles.css";

import whats from "../../assets/whats.png";

const WppFlutuante = () => {
  return (
    <section id="wppFlutuante">
      <a
        href="http://wa.me/+5592993310599?text=Gostaria%20de%20saber%20mais"
        target="_blank"
        rel="noreferrer"
      >
        <figure>
          <img src={whats} alt="Ícone do Whatsapp" />
        </figure>
      </a>
    </section>
  );
};

export default WppFlutuante;
