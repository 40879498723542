import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Menu from "./components/Menu";
import LGPD from "./components/LGPD";
import Footer from "./components/Footer";
import WppFlutuante from "./components/WppFlutuante";

import { Header, About } from "./pages";

function App() {
  return (
    <div className="App">
      <LGPD />
      <Menu />
      <WppFlutuante />
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Header />
                <About />
              </>
            }
          ></Route>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
