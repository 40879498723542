import "./styles.css";

import CtaWpp from "../../components/CTAWpp";

import header2_1 from "../../assets/header2_1.png";
import header2_2 from "../../assets/header2_2.png";
import header2_3 from "../../assets/header2_3.png";
import header2_1_text from "../../assets/header2_1_text.png";
import header2_2_text from "../../assets/header2_2_text.png";
import header2_3_text from "../../assets/header2_3_text.png";

const Header = () => {
  return (
    <header id="header">
      <section className="header1">
        <article className="container vertical">
          <h1 className="title">
            <b>
              Limpeza, instalação e manutenção de <span>Ar condicionados</span>
            </b>{" "}
            residenciais, empresariais e industriais.
          </h1>

          <CtaWpp />
        </article>
      </section>

      <section className="header2">
        <article className="container vertical">
          <h2 className="title">Nosso Serviços</h2>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={header2_1} alt="Instalação de Ar Condicionado" />
              </figure>

              <div className="bottom">
                <p className="item_title">Instalação de Ar Condicionado</p>
                <figure className="figure_text">
                  <img
                    src={header2_1_text}
                    alt="Nas nossas instalações utilizamos material novo e de qualidade, damos vácuo no sistema com bomba de vácuo, que é um procedimento exigido pelo fabricante. Técnicos qualificados e ferramental completo, garantindo assim total qualidade no serviço prestado. Garantia de 1 ano no serviço de instalação."
                  />
                </figure>

                <CtaWpp text={"Orçamento"} />
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img
                  src={header2_2}
                  alt="Limpeza e Higienização de Ar Condicionado"
                />
              </figure>

              <div className="bottom">
                <p className="item_title">
                  Limpeza e Higienização de Ar Condicionado
                </p>
                <figure className="figure_text">
                  <img
                    src={header2_2_text}
                    alt="Realizamos a limpeza no local de instalação do seu equipamento. Com esse método, evita-se a perda do gás refrigerante, risco de manutenção corretiva, economia de tempo, menos sujeira no local, prolonga a vida útil do aparelho. Toda a limpeza é feita no local, sem a necessidade de remover a máquina da parede."
                  />
                </figure>

                <CtaWpp text={"Orçamento"} />
              </div>
            </div>

            <div className="item">
              <figure className="top">
                <img
                  src={header2_3}
                  alt="Contrato de manutenção para sua empresa"
                />
              </figure>

              <div className="bottom">
                <p className="item_title">
                  Contrato de manutenção para sua empresa
                </p>
                <figure className="figure_text">
                  <img
                    src={header2_3_text}
                    alt="Fazemos contratos de manutenção preventiva e corretiva. Emitimos laudos, certificados e PMOC.
                    Equipes especializadas, ferramentas adequadas, compromisso e responsabilidade com datas e prazos. Visita técnica GRATÍS para o orçamento."
                  />
                </figure>

                <CtaWpp text={"Orçamento"} />
              </div>
            </div>
          </div>
        </article>
      </section>
    </header>
  );
};

export default Header;
