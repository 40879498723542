import "./styles.css";

import CtaWpp from "../../components/CTAWpp";
import CtaMenu from "../../components/CtaMenu";

import about1_1 from "../../assets/about1_1.png";
import about1_2 from "../../assets/about1_2.png";
import about1_3 from "../../assets/about1_3.png";
import about1_4 from "../../assets/about1_4.png";
import about3 from "../../assets/about3.png";

const About = () => {
  return (
    <section id="about">
      <article className="about1">
        <div className="container vertical">
          <h2 className="title">Por que escolher a resfriar manaus?</h2>

          <p className="text">
            Somos uma empresa com mais de 10 anos de experiência e milhares de
            clientes satisfeitos, com nosso trabalho realizador por uma equipe
            de profissionais qualificados e comprometidos em entregar sempre o
            melhor resultado.
          </p>

          <div className="gallery">
            <div className="item">
              <figure className="top">
                <img src={about1_1} alt="Compromisso e garantia" />
              </figure>

              <p className="item_title">Compromisso e garantia</p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_2} alt="Agilidade no Atendimento" />
              </figure>

              <p className="item_title">Agilidade no Atendimento</p>
            </div>

            <div className="item">
              <figure className="top">
                <img
                  src={about1_3}
                  alt="Equipe profissional e materiais de qualidade"
                />
              </figure>

              <p className="item_title">
                Equipe profissional e materiais de qualidade
              </p>
            </div>

            <div className="item">
              <figure className="top">
                <img src={about1_4} alt="Pontualidade, Sempre" />
              </figure>

              <p className="item_title">Pontualidade, Sempre</p>
            </div>
          </div>

          <CtaWpp />
        </div>
      </article>

      <article className="about2">
        <div className="container vertical">
          <h2 className="title">Sobre nós</h2>

          <p className="text">
            A Resfriar Manaus é uma empresa especializada em climatização de
            ambientes atuando na  instalação e manutenção de ar condicionado  em
            empresas e residências. Criada há mais de 10 anos com o objetivo de
            levar até nossos clientes um alto padrão de serviços e equipamento
            de ar condicionado e refrigeração, a empresa vem se destacando a
            cada dia no mercado. Para isso conta com uma equipe altamente
            qualificada e treinada junto, técnicos uniformizados, atendimento
            personalizado, suporte técnico, assistência técnica e suporte pós
            vendas para manter os equipamentos em perfeito estado de
            funcionamento.
          </p>

          <p className="text2">Fale conosco via telefone ou WhatsApp:</p>

          <CtaMenu />
        </div>
      </article>

      <article className="about3">
        <div className="container">
          <h2 className="title">formas de pagamento</h2>

          <p className="text">crédito, débito, ESPÉCIE e PIX</p>

          <figure>
            <img src={about3} alt="Bandeiras de cartões aceitos" />
          </figure>
        </div>
      </article>
    </section>
  );
};

export default About;
